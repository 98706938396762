import ApiService from './api.service'
const ComplaintService = {
  GetList(data) {
    return ApiService.post(`/Complaint/GetList`, data);
  },
  Get() {
    return ApiService.get(`/Complaint/Get`);
  },
  Get(id) {
    return ApiService.get(`/Complaint/Get/${id}`);
  },
  GetByRequestId(requestId) {
    return ApiService.get(`/Complaint/GetByRequestId/${requestId}`);
  },
  // Create(data) {
  //     return ApiService.post(`/Complaint/Create`, data)
  // },
  // Update(data) {
  //     return ApiService.post(`/Complaint/Update`, data)
  // },
  Update(data) {
    if (data.id == 0) {
      return ApiService.post(`/Complaint/Create`, data);
    } else {
      return ApiService.post(`/Complaint/Update`, data);
    }
  },
  Send(data) {
    return ApiService.post(`/Complaint/Send`, data);
  },
  GetHistoryList(complaintId) {
    return ApiService.post(`/Request/GetHistoryList/${complaintId}`);
  },
  GetSecurityInfo() {
    return ApiService.get(`/Request/GetSecurityInfo`);
  },
};
export default ComplaintService